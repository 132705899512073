// Adapted from react-redux, this version of shallowEqual
// requires a depth to specify how shall it is.
// This is super useful when the connect function
// uses a superficial transform (e.g. creating
// an array or map).

const hasOwn = Object.prototype.hasOwnProperty;

function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    // eslint-disable-next-line no-self-compare
    return x !== x && y !== y;
  }
}

export default function shallowEqualDepth(objA, objB, depth) {
  if (is(objA, objB)) return true;
  if (depth === 0) return false;

  if (typeof objA !== "object" || objA === null || typeof objB !== "object" || objB === null) {
    return false;
  }

  if (objA instanceof Map) {
    if (!(objB instanceof Map)) {
      return false;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return shallowMapEqual(objA, objB, depth);
    }
  } else if (objB instanceof Map) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < keysA.length; i++) {
    const k = keysA[i];
    if (!hasOwn.call(objB, keysA[i]) || !shallowEqualDepth(objA[k], objB[k], depth - 1)) {
      return false;
    }
  }
  return true;
}

function shallowMapEqual(mapA, mapB, depth) {
  if (mapA.size !== mapB.size) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const k of mapA.keys()) {
    if (!shallowEqualDepth(mapA.get(k), mapB.get(k), depth - 1)) {
      return false;
    }
  }
  return true;
}
